import React from 'react';

function Counter(props) {
    return (
        <div className="tp-counter-area pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30 wow tpfadeUp" data-wow-duration=".7s"
                        data-wow-delay=".5s">
                        <div className="counter-item text-center">
                            <h4><span className="">220</span>+</h4>
                            <h3>Projects deliverd</h3>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30 wow tpfadeUp" data-wow-duration=".7s"
                        data-wow-delay=".7s">
                        <div className="counter-item text-center">
                            <h4><span className="">8</span>+</h4>
                            <h3>Years of operation</h3>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30 wow tpfadeUp" data-wow-duration=".7s"
                        data-wow-delay=".9s">
                        <div className="counter-item text-center">
                            <h4><span className="">10</span>+</h4>
                            <h3>Specialist Member</h3>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30 wow tpfadeUp" data-wow-duration=".7s"
                        data-wow-delay="1s">
                        <div className="counter-item text-center">
                            <h4><span className="">100</span>+</h4>
                            <h3>Satisfied clients</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Counter;