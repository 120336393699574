import React from 'react';
import { NavLink } from "react-router-dom";

function Banner({ name, breadcrums }) {
    return (
        <section className="breadcrumb__area breadcrumb-height include-bg p-relative"
            data-background={require('../Assets/img/breadcrumb/breadcurmb.jpg')}>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="breadcrumb__content">
                            <h3 className="breadcrumb__title wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".5s">{name}</h3>
                            <div className="breadcrumb__list wow tpfadeUp" data-wow-duration=".9s">
                                {
                                    breadcrums[0]?.name ? <span><NavLink to={breadcrums[0].link}>{breadcrums[0].name}</NavLink></span> : null
                                }
                                {
                                    breadcrums[1]?.name ?  <><span className="dvdr"><i className="fa fa-angle-right"></i></span> <span><NavLink to={breadcrums[1].link}>{breadcrums[1].name}</NavLink></span></> : null
                                }
                                {
                                    breadcrums[2]?.name ?  <><span className="dvdr"><i className="fa fa-angle-right"></i></span> <span><NavLink to={breadcrums[2].link}>{breadcrums[2].name}</NavLink></span></> : null
                                }
                                {/* <span><NavLink to={"/"}>Home</NavLink></span>
                                <span className="dvdr"><i className="fa fa-angle-right"></i></span>
                                <span>About us</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;