import React, { useState } from 'react';

import { NavLink } from "react-router-dom";


function Navbar(props) {
    const [open, openDocker] = useState(false);

    const onDockerClick = (val) => {
        openDocker(val);
    }

    return (
        <>
            <header>
                <div id="header-sticky" className="tp-header-area header-pl-pr header-transparent header-border-bottom">
                    <div className="container-fluid">
                        <div className="row g-0 align-items-center">
                            <div className="col-xl-2 col-lg-2 col-md-4 col-6">
                                <div className="tp-logo tp-logo-border">
                                    <NavLink to={"/"} className="header-logo">
                                        {/* <img src={require('../Assets/img/logo/logo-1.png" alt="" /> */}
                                        <img src={require('../Assets/img/logo/logo-1.png')} alt="" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-xl-10 col-lg-10 col-md-8 col-6 d-flex justify-content-end">
                                <div className="tp-main-menu d-none d-xl-block">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li>
                                                {/* <a href="index.html">Home</a> */}
                                                <NavLink to={'/'}>Home</NavLink>
                                            </li>
                                            <li>
                                                {/* <a href="about-us.html">About</a> */}
                                                <NavLink to={'/about-us'}>About</NavLink>
                                            </li>
                                            <li>
                                                {/* <a href="case-studies.html">Our Project</a> */}
                                                <NavLink to={'/case-studies'}>Our Projects</NavLink>
                                            </li>
                                            <li>
                                                {/* <a href="service.html">
                                                    Services
                                                </a> */}
                                                <NavLink to={'/services'}>Services</NavLink>
                                            </li>
                                            <li>
                                                {/* <a href="contact.html">Contact</a> */}
                                                <NavLink to={'/blogs'}>Blogs</NavLink>
                                            </li>
                                            <li>
                                                {/* <a href="contact.html">Contact</a> */}
                                                <NavLink to={'/contact'}>Contacts</NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="tp-header-right">
                                    <ul>
                                        <li className=" d-none d-md-inline-block search-wrapper">
                                            <a className="tp-search-box" target="_blank"
                                                href="https://wa.me/+918983418183?text=I%20want%20to%20know%20more"><i
                                                    className="fab fa-whatsapp"></i></a>
                                        </li>
                                        <li><a className="tp-menu-bar" onClick={() => onDockerClick(true)}><i className="fas fa-bars"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="tp-offcanvas-area">
                <div className={`tpoffcanvas  ${open ? 'opened' : ''}`}>
                    <div className="tpoffcanvas__close-btn" onClick={() => onDockerClick(false)}>
                        <button className="close-btn"><i className="fal fa-times"></i></button>
                    </div>
                    <div className="tpoffcanvas__logo pt-50">
                        <NavLink to={"/"}>
                            <img src={require('../Assets/img/logo/logo-2.png')} alt="" />
                        </NavLink>
                    </div>
                    <div className="tpoffcanvas__text">
                        <p>Pixelar Solutions: Crafting digital excellence with innovative web and mobile solutions for businesses
                            seeking a dynamic online presence.</p>
                    </div>
                    <div className="mobile-menu">
                        <div class="mobile-menu mean-container"><div class="mean-bar"><a href="#nav" class="meanmenu-reveal"><span><span><span></span></span></span></a><nav class="mean-nav">
                            <ul>
                                <li>
                                    {/* <a href="index.html">Home</a> */}
                                    <NavLink to={'/'}>Home</NavLink>
                                </li>
                                <li>
                                    {/* <a href="about-us.html">About</a> */}
                                    <NavLink to={'/about-us'}>About</NavLink>
                                </li>
                                <li>
                                    {/* <a href="case-studies.html">Our Project</a> */}
                                    <NavLink to={'/case-studies'}>Our Projects</NavLink>
                                </li>
                                <li>
                                    {/* <a href="service.html">
                                                    Services
                                                </a> */}
                                    <NavLink to={'/services'}>Services</NavLink>
                                </li>
                                <li>
                                    {/* <a href="contact.html">Contact</a> */}
                                    <NavLink to={'/blogs'}>Blogs</NavLink>
                                </li>
                                <li>
                                    {/* <a href="contact.html">Contact</a> */}
                                    <NavLink to={'/contact'}>Contacts</NavLink>
                                </li>
                            </ul>
                        </nav></div></div>
                    </div>
                    <div className="tpoffcanvas__info">
                        <h3 className="offcanva-title">Get In Touch</h3>
                        <div className="tp-info-wrapper mb-20 d-flex align-items-center">
                            <div className="tpoffcanvas__info-icon">
                                <a href="#"><i className="fal fa-envelope"></i></a>
                            </div>
                            <div className="tpoffcanvas__info-address">
                                <span>Email</span>
                                <a href="maito:info@pixelarsolution.com">info@pixelarsolution.com</a>
                            </div>
                        </div>
                        <div className="tp-info-wrapper mb-20 d-flex align-items-center">
                            <div className="tpoffcanvas__info-icon">
                                <a href="#"><i className="fal fa-phone-alt"></i></a>
                            </div>
                            <div className="tpoffcanvas__info-address">
                                <span>Phone</span>
                                <a href="tel:8983418183">8983418183</a>
                            </div>
                        </div>
                        <div className="tp-info-wrapper mb-20 d-flex align-items-center">
                            <div className="tpoffcanvas__info-icon">
                                <a href="#"><i className="fas fa-map-marker-alt"></i></a>
                            </div>
                            <div className="tpoffcanvas__info-address">
                                <span>Location</span>
                                <a href="https://maps.app.goo.gl/m8M71uYPkfM1eybw5" target="_blank">106, Rohit Palm, North Wing,
                                    Rahatani <br />Pune, Maharashtra</a>
                            </div>
                        </div>
                    </div>
                    <div className="tpoffcanvas__social">
                        <div className="social-icon">
                            <a href="https://www.instagram.com/pixelarsolutions/" target="_blank"><i className="fab fa-instagram"></i></a>
                            <a href="https://www.facebook.com/pixelarsolutions" target="_blank"><i className="fab fa-facebook-square"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;