import React, {useEffect} from 'react';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Terms(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <main>
                <div className="terms-used-page mt-160 mb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="text-center mb-60">
                                    Terms of use
                                </h2>
                                <p><strong></strong>
                                    Last Updated: January 21, 2024
                                </p>

                                <p>
                                    Welcome to Pixelar Solutions. By accessing and using our website <a href="http://www.pixelarsolutions.com">www.pixelarsolutions.com</a>, you agree to comply with and be bound by the following terms and conditions:
                                </p>

                                <p>
                                    <strong>Use of Website</strong>
                                    <p>
                                        The content of this website is for your general information and use only. It is subject to change without notice. We reserve the right to modify or discontinue any aspect or feature of our website without prior notice.
                                    </p>

                                    <p>
                                        <strong>Intellectual Property</strong>
                                        <p>
                                            This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited without explicit permission.
                                        </p>

                                        <p>
                                            <strong>External Links</strong>
                                        </p>
                                        <p>Our website may contain links to external websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                                        </p>

                                        <p>
                                            <strong>Disclaimer</strong>
                                        </p>
                                        <p>
                                            The information contained on this website is for general information purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.
                                        </p>

                                        <p>
                                            <strong>Changes to Terms</strong>
                                        </p>
                                        <p>
                                            We may revise these terms of use at any time without notice. By using this website, you are agreeing to be bound by the then-current version of these terms of use.
                                        </p>

                                        <p>
                                            <strong>Contact Us</strong>
                                        </p>
                                        <p>
                                            If you have any questions or concerns about these terms of use, please contact us at.
                                        </p>
                                        <p>Pixelar solutions</p>
                                        <p>Phone: 8983418183</p>
                                        <p>Email:
                                            <a href="mailto: info@pixelarsolutions.com">info@pixelarsolutions.com</a>
                                        </p>
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    );
}

export default Terms;