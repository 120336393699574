import React, {useEffect} from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function PrivacyPolicy(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <main>
                <div className="privacy-policy-page mt-160 mb-60">
                    <div className="container">
                        <div className="row mt-30 mb-30">
                            <div className="col-lg-12">
                                <h2 className="text-center mb-60">
                                    Privacy policy
                                </h2>
                                <p>
                                    Last updated: 21 Jan 2024
                                </p>
                                <p>
                                    Pixelar solutions, hereafter referred to as “we,” “us,” or “our,” operates the website <a href="www.pixelarsolution.com">www.pixelarsolution.com</a>. This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.
                                </p>
                                <p>
                                    <strong> Information Collection and Use:</strong>
                                </p>
                                <p>
                                    While using our website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include but is not limited to your name, email address, phone number, and company details (“Personal Information”).
                                </p>
                                <p>
                                    <strong>Log Data:</strong>
                                </p>
                                <p>
                                    Like many site operators, we collect information that your browser sends whenever you visit our site (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                                </p>
                                <p>
                                    <strong>Communications:</strong>
                                </p>
                                <p>
                                    We may use your Personal Information to contact you with newsletters, marketing, or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
                                </p>

                                <p>
                                    <strong>Security:</strong>
                                </p>
                                <p>
                                    The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                                </p>

                                <p>
                                    <strong> Changes to This Privacy Policy:</strong>
                                </p>
                                <p>
                                    This Privacy Policy is effective as of 21st jan 2024 and will remain in effect except concerning any changes in its provisions in the future, which will be in effect immediately after being posted on this page
                                </p>.
                                <p>
                                    We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                                </p>
                                <p>
                                    If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us or by placing a prominent notice on our website.
                                </p>

                                <p>
                                    <strong>Contact Us:</strong>
                                </p>
                                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                                <p>Pixelar solutions</p>
                                <p>Phone: 8983418183</p>
                                <p>Email:
                                    <a href="mailto: info@pixelarsolutions.com">info@pixelarsolutions.com</a>
                                </p>
                                <p>
                                    By using this website, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our website. Your continued use of the website following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default PrivacyPolicy;