import React, {useEffect} from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Banner from '../Components/Banner'

function Contact(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Banner name="Contact Us" breadcrums={[{ name: 'Home', link: "/" }, { name: "Contact Us", link: "" }]} />

            <main>
                <div class="tp-contact-area pt-130 pb-130">
                    <div class="container">
                        <div class="row g-0 align-items-center justify-content-center">
                            <div class="col-xl-4 col-lg-4 col-md-5 col-12">
                                <div class="contact-box">
                                    <div class="contact-box-circle">
                                        <span>O</span>
                                        <span>R</span>
                                    </div>
                                    <h3 class="contact-box__title">Contact <br /> Directly</h3>
                                    <div class="contact-box__info-list">
                                        <ul>
                                            <li><a href="mob:8983418183"><i class="fal fa-phone-alt"></i>8983418183</a></li>
                                            <li><a href="https://maps.app.goo.gl/m8M71uYPkfM1eybw5" target="_blank"><i class="fal fa-map-marker-alt"></i>106, Rohit Palm, North Wing, Rahatani <br />Pune, Maharashtra</a></li>
                                            <li><a href="mailto:info@pixelarsolutions.com"><i class="fal fa-globe"></i>info@pixelarsolutions.com</a></li>
                                        </ul>
                                    </div>
                                    <div class="contact-box__social">
                                        <ul>
                                            <li><a href="https://www.instagram.com/pixelarsolutions/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.facebook.com/pixelarsolutions" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-7 col-12">
                                <div class="postbox__comment-form contact-wrapper">
                                    <h3 class="postbox__comment-form-title">Send us a
                                        Message</h3>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="postbox__comment-input">
                                                    <input type="text" placeholder="Enter your name" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="postbox__comment-input">
                                                    <input type="email" placeholder="Enter your mail" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="postbox__comment-input">
                                                    <textarea placeholder="Enter your message"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="postbox__comment-btn">
                                                    <button type="submit" class="tp-btn">Let,s Talk</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
}

export default Contact;