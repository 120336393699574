import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Blogs(props) {
    return (
        <div>
            <section class="breadcrumb__area breadcrumb-height include-bg p-relative" data-background="assets/img/breadcrumb/breadcurmb.jpg">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="breadcrumb__content">
                                <h3 class="breadcrumb__title">Recent Blog</h3>
                                <div class="breadcrumb__list wow tpfadeUp" data-wow-duration=".9s">
                                    <span><Link to={"/"}>Home</Link></span>
                                    <span class="dvdr"><i class="fa fa-angle-right"></i></span>
                                    <span>Blog</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="postbox__area pt-120 pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="postbox__wrapper pr-20">
                                <article class="postbox__item format-image mb-50 transition-3">
                                    <div class="postbox__thumb w-img">
                                        <a href="blog-details.html">
                                            <img src={require("../Assets/img/blog/Software_White_Labeling.png")} alt="" />
                                        </a>
                                    </div>
                                    <div class="postbox__content">
                                        <div class="postbox__meta">
                                            <span><a href="#"><i class="fal fa-user-circle"></i> Admin </a></span>
                                            <span><a href="#"><i class="fal fa-clock"></i> June 30, 2024</a></span>
                                            {/* <span><a href="#"><i class="fal fa-comment-alt-lines"></i>(04) Coments</a></span> */}
                                        </div>
                                        <h3 class="postbox__title">
                                            <Link to={"software-white-labeling"}>Unlocking the Potential of Software White Labeling</Link>
                                        </h3>
                                        <div class="postbox__text">
                                            <p> White labeling refers to providing services without your name to other service providers and allowing them to sell the service as their own service.</p>
                                        </div>
                                        <div class="post__button">
                                            <Link to={"software-white-labeling"} class="tp-btn-sm" > READ MORE</Link>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;