import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify'
import { useLocation } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import Banner from '../Components/Banner';
import ThinkMakeSolve from '../Components/ThinkMakeSolve';
import Footer from '../Components/Footer';

const PageNameObj = {
    1: {
        name: "Web Development",
        breadcrums: [{ name: 'Home', link: "/" }, { name: "Services", link: "/services" }, { name: "Web Development", link: "" }],
        imgSrc: require("../Assets/img/service/sv-details-1.jpg"),
        content: `
        <p>Welcome to Pixelar Solutions, where we redefine your online presence through our expert
        <strong>Website Development Service</strong>. In today's digital age, a well-crafted website is
        the cornerstone of a successful online strategy. Our dedicated team at Pixelar Solutions is here
        to transform your vision into a dynamic, visually appealing, and high-performance website that
        not only meets but exceeds your business goals.</p>

    <p><strong>Why Choose Pixelar Solutions for Your Website Development Needs?</strong></p>

    <p>At Pixelar Solutions, we believe in a holistic approach to website development. Our process
        begins with in-depth consultations to understand your business objectives, target audience, and
        unique selling propositions. Armed with this knowledge, we embark on a journey to create a
        website that not only showcases your brand but also engages and converts visitors into
        customers.</p>

    <p><strong>Key Features of Our Website Development Service:</strong></p>

    <ul>
        <li className="mb-5"><strong>Custom Development:</strong> Tailoring websites to suit your specific business
            requirements and objectives.</li>
        <li className="mb-5"><strong>Responsive Design:</strong> Ensuring seamless user experiences across various
            devices and screen sizes.</li>
        <li className="mb-5"><strong>Scalability:</strong> Building websites that grow with your business, adapting to
            evolving needs and demands.</li>
        <li className="mb-5"><strong>User-Centric Approach:</strong> Prioritizing intuitive navigation and engaging
            content for a positive user experience.</li>
        <li className="mb-5"><strong>Latest Technologies:</strong> Leveraging cutting-edge frameworks and technologies
            for enhanced functionality and performance.</li>
        <li className="mb-5"><strong>Security Measures:</strong> Implementing robust security protocols to safeguard your
            website and user data.</li>
        <li className="mb-5"><strong>SEO Optimization:</strong> Ensuring your website is discoverable with strategic
            SEO-friendly structures and content.</li>
    </ul>

    <p>Whether you are looking for a <strong>static website</strong> to establish your online presence
        or a <strong>dynamic web application</strong> for more interactive functionalities, Pixelar
        Solutions has the expertise to bring your vision to life. Our commitment goes beyond the
        development phase; we provide ongoing support, regular updates, and maintenance to ensure your
        website remains at the forefront of industry standards.</p>

    <p><strong>Ready to embark on a digital journey?</strong> Contact Pixelar Solutions today for a
        detailed consultation. Let's collaborate to shape the future of your digital landscape and
        propel your business to new heights.</p>
        `
    },
    2: {
        name: "Web app for business",
        breadcrums: [{ name: 'Home', link: "/" }, { name: "Services", link: "/services" }, { name: "Web app for business", link: "" }],
        imgSrc: require("../Assets/img/service/sv-details-2.jpg"),
        content: `
        <p>
        In the ever-evolving digital landscape, businesses need robust and tailored solutions to stay ahead. Pixelar Solutions introduces its cutting-edge Web Application for Business service, designed to elevate your operations, enhance user experiences, and drive unprecedented efficiency in your day-to-day processes
     </p>

     <p>
        <strong>Tailored Solutions for Your Unique Needs:</strong>
    <br>
        Pixelar Solutions understands that each business is unique, with specific challenges and objectives. Our Web Application service is meticulously crafted to address your organization's individual needs. Whether it's streamlining internal workflows, improving customer engagement, or facilitating seamless communication, our solutions are tailored to bring value to your specific business model.s
     </p>
     <p>
        <strong>Strategic Planning for Optimal Results:</strong>
        <br>
        Our process begins with strategic planning. We collaborate closely with your team to gain a deep understanding of your business goals, user requirements, and industry nuances. This information becomes the foundation for a comprehensive and strategic web application development plan, ensuring that the final product aligns seamlessly with your business objectives.
     <p>
        <strong>User-Centric Design and Intuitive Functionality:</strong>
        <br>
        User experience is at the forefront of our approach. Pixelar Solutions combines aesthetically pleasing design with intuitive functionality to ensure that your web application not only meets but exceeds user expectations. We prioritize ease of use, responsiveness, and a visually appealing interface to enhance user engagement and satisfaction.
     </p>
     <p>
        <strong> Scalability and Future-Proofing:</strong>
        <br>
        Our web applications are built for scalability. As your business grows, our solutions adapt to accommodate increased demands and evolving requirements. With an eye on the future, Pixelar Solutions creates web applications that are not only powerful today but can easily scale to meet the demands of tomorrow.
     </p>
     <p>
        <strong> Integration of Advanced Technologies: </strong>
        <br>
        Pixelar Solutions leverages state-of-the-art technologies to build powerful and efficient web applications. From integrating artificial intelligence for smart functionalities to utilizing the latest frameworks for robust performance, we ensure that your web application is equipped with the tools needed to stay competitive in a dynamic business environment.
     </p>
     <p>
        <strong>Security and Reliability: </strong>
        <br>
        Security is paramount in the digital realm. Pixelar Solutions employs industry-best practices to ensure the security and integrity of your web application. Our robust security measures safeguard your data, transactions, and user information, providing peace of mind for both you and your users.
     </p>
     <p>
        <strong> Ongoing Support and Maintenance:</strong>
        <br>
        Our commitment doesn't end with the launch. Pixelar Solutions provides ongoing support and maintenance services to keep your web application running smoothly. We stay responsive to updates, security patches, and any evolving needs, ensuring the sustained success of your digital solution.
     </p>
     <p>
        <strong> Unlock the Potential of Your Business:  </strong>
        <br>
        With Pixelar Solutions' Web Application for Business service, unlock the full potential of your business operations. Join us in shaping the future of your enterprise with a powerful, scalable, and user-centric web application designed to propel your business to new heights.                    
     </p>
        `
    },
    3: {
        name: "E-Commerce solutions",
        breadcrums: [{ name: 'Home', link: "/" }, { name: "Services", link: "/services" }, { name: "E-Commerce solutions", link: "" }],
        imgSrc: require("../Assets/img/service/sv-details-3.jpg"),
        content: `
        <p>
        Welcome to Pixelar Solutions, where we redefine digital retail through our comprehensive and innovative e-commerce services. Our tailored solutions empower businesses to thrive in the competitive online marketplace, offering a seamless and engaging shopping experience for both merchants and customers.
     </p>

     <p>
        <strong>Custom E-Commerce Development:</strong>
    <br>
        Pixelar Solutions understands that each business is unique, with specific challenges and objectives. Our Web Application service is meticulously crafted to address your organization's individual needs. Whether it's streamlining internal workflows, improving customer engagement, or facilitating seamless communication, our solutions are tailored to bring value to your specific business model.s
     </p>
     <p>
        <strong>Strategic Planning for Optimal Results:</strong>
        <br>
        Pixelar Solutions specializes in crafting bespoke e-commerce solutions tailored to the unique needs of each client. From intuitive user interfaces to robust back-end systems, our custom development ensures a platform that aligns perfectly with your brand identity and business goals.
    </p>
    <p>
        <strong>User-Centric Design:</strong>
        <br>
        We prioritize user experience in every aspect of our e-commerce development. Our designs are not just visually appealing but also intuitive, ensuring that visitors can easily navigate through your product catalog, make purchases, and enjoy a hassle-free shopping journey.
    </p>
    <p>
        <strong> Scalability and Future-Proofing:</strong>
        <br>
        Our web applications are built for scalability. As your business grows, our solutions adapt to accommodate increased demands and evolving requirements. With an eye on the future, Pixelar Solutions creates web applications that are not only powerful today but can easily scale to meet the demands of tomorrow.
    </p>
    <p>
        <strong>Secure Payment Gateways: </strong>
        <br>
        Security is paramount in online transactions. Pixelar Solutions integrates secure and reliable payment gateways, providing customers with a range of payment options while ensuring that their sensitive information is protected through encryption and industry-standard security measures.
    </p>
    <p>
        <strong>Product Catalog Management:</strong>
        <br>
        Efficiently manage your product catalog with our intuitive back-end systems. Pixelar Solutions' e-commerce services include robust product catalog management, enabling easy addition, update, and categorization of products for a dynamic and organized online storefront.
    </p>
    <p>
        <strong> Mobile-Optimized Shopping:</strong>
        <br>
        In the era of mobile commerce, we ensure that your e-commerce platform is optimized for mobile devices. Responsive design and mobile-friendly interfaces guarantee a consistent and enjoyable shopping experience across smartphones and tablets.
     </p>
    <p>
        <strong> E-Commerce Analytics:</strong>
        <br>
        Gain valuable insights into your online business with our integrated analytics solutions. Track user behavior, monitor sales trends, and gather actionable data to make informed decisions and optimize your e-commerce strategy for growth.                    
     </p>
    <p>
        <strong> Inventory Management:</strong>
        <br>
        Efficient inventory management is crucial for e-commerce success. Pixelar Solutions' e-commerce services include robust inventory management systems, helping you keep track of stock levels, automate reorder processes, and avoid disruptions in supply.                   
     </p>
     <p>
        <strong>Shipping and Logistics Integration:</strong>
        <br>
        Streamline your shipping processes with seamless integration with logistics partners. Pixelar Solutions ensures that your e-commerce platform integrates smoothly with shipping carriers, providing real-time shipping rates, order tracking, and a transparent shipping experience for your customers.
     </p>
     <p>
        <strong>Responsive Customer Support::</strong>
        <br>
        We understand the importance of customer support in e-commerce. Our solutions include features for responsive customer support, including live chat, FAQs, and contact forms, ensuring that your customers feel supported throughout their online shopping journey.
     </p>
     <p>
        <strong>E-Commerce SEO Optimization:</strong>
        <br>
        Drive organic traffic to your online store with our e-commerce SEO optimization services. Pixelar Solutions ensures that your products are discoverable, leading to increased visibility and higher rankings in search engine results.
     </p>
     <p>
        <strong>Ongoing Support and Upgrades:</strong>
        <br>
        Our commitment to your e-commerce success doesn't end with the launch. Pixelar Solutions provides ongoing support, maintenance, and upgrades to keep your e-commerce platform up-to-date, secure, and aligned with the latest industry trends.
     </p>

     <p>
        Pixelar Solutions invites you to embark on a journey of e-commerce excellence. Whether you are launching a new online store or looking to enhance an existing platform, our e-commerce services are designed to elevate your digital retail presence, drive sales, and exceed customer expectations.
     </p>
        `
    },
    4: {
        name: "Social media management",
        breadcrums: [{ name: 'Home', link: "/" }, { name: "Services", link: "/services" }, { name: "Social media management", link: "" }],
        imgSrc: require("../Assets/img/service/sv-details-4.jpg"),
        content: `
        <p>
        In the dynamic world of social media, Pixelar Solutions emerges as your strategic partner, offering comprehensive and innovative social media management services. We understand the pivotal role social platforms play in shaping brand perception, driving engagement, and fostering meaningful connections with your audience. Our services are designed to empower businesses with a strong and influential presence across diverse social channels.
     </p>

     <p>
        <strong>Strategic Planning: </strong>
    <br>
        Pixelar Solutions understands that each business is unique, with specific challenges and objectives. Our Web Application service is meticulously crafted to address your organization's individual needs. Whether it's streamlining internal workflows, improving customer engagement, or facilitating seamless communication, our solutions are tailored to bring value to your specific business model.s
     </p>
     <p>
        <strong>Strategic Planning for Optimal Results:</strong>
        <br>
        Tailored social media strategies aligned with business objectives, driven by research and comprehensive planning.
    </p>
    <p>
        <strong>Compelling Content:</strong>
        <br>
        Engaging and shareable content crafted by our creative team, optimizing visuals and multimedia across platforms.
    </p>
    <p>
        <strong>Targeted Advertising:</strong>
        <br>
        Precision in social media advertising to maximize reach and ROI through platforms like Facebook, Instagram, Twitter, and LinkedIn.
    </p>
    <p>
        <strong>Community Engagement: </strong>
        <br>
        Active engagement with your audience, fostering meaningful interactions and turning followers into brand advocates.
    </p>
    <p>
        <strong>Analytics and Reporting:</strong>
        <br>
        Data-driven insights, metrics tracking, and continuous optimization to measure the success of your social campaigns.
    </p>
    <p>
        <strong> Influencer Collaborations:</strong>
        <br>
        Strategic partnerships with influencers to enhance brand credibility and expand reach authentically.
     </p>
    <p>
        <strong> Crisis Management: </strong>
        <br>
        Proactive handling of crises, ensuring transparent communication and protecting brand reputation.                    
     </p>
    <p>
        <strong> Platform-Specific Expertise: </strong>
        <br>
        Tailoring strategies for various platforms, staying abreast of trends, and adapting to algorithm changes.                  
     </p>
     <p>
        <strong>Consultation and Collaboration:</strong>
        <br>
        Ongoing consultation and collaboration, keeping you informed about strategy adjustments, new opportunities, and industry updates.
     </p>
     <p>
        <strong>Elevate Your Social Presence:</strong>
        <br>
        Goin Pixelar Solutions in crafting a vibrant social presence that resonates, engages, and propels your brand to new heights.
     </p>
     

     <p>
        <strong>Ready to Make Waves? </strong> Contact Pixelar Solutions today and let's unleash the potential of your social media strategy.
     </p>
        `
    },
    5: {
        name: "Mobile app development",
        breadcrums: [{ name: 'Home', link: "/" }, { name: "Services", link: "/services" }, { name: "Mobile app development", link: "" }],
        imgSrc: require("../Assets/img/service/sv-details-5.jpg"),
        content: `
        <p>
        At Pixelar Solutions, we are your partners in turning ideas into innovative and user-friendly mobile applications. Our mobile app development services are designed to meet the diverse needs of businesses, delivering solutions that combine functionality, aesthetics, and seamless user experiences.
     </p>
     <!-- <p>
        Our objective is to create an online presense for your business or website for your business to increase leads.            
     </p>
     <p>
        We use all the best practices to ensure good on page and off page seo for your website so that your website may appear in the google search in lesser time, generating more leads for your business.
     </p>
     <P>
        Here’s what will be included on your website:
     </P> -->

     <p>
        <strong>Custom Development:  </strong>
    <br>
        We specialize in crafting custom mobile applications tailored to your unique requirements, ensuring a perfect fit for your business objectives.
     </p>
     <p>
        <strong>User-Centric Design:</strong> 
        <br>
        Our approach prioritizes user experience, creating intuitive and visually appealing interfaces that captivate and engage users.
    </p>

     <p>
        <strong>Cross-Platform Expertise:</strong>
        <br>
        Whether iOS or Android, Pixelar Solutions excels in cross-platform development, ensuring your app reaches a broad audience.
    </p>

     <p>
        <strong>Cutting-Edge Technologies:</strong>
        <br>
        We leverage the latest technologies and frameworks to develop feature-rich and future-ready mobile applications.
    </p>
 
     <p>
        <strong>Scalability and Performance:</strong>
        <br>
         Our mobile apps are built for scalability, ensuring they grow with your business, and are optimized for top-notch performance.
        </p>
 
     <p>
        <strong>Quality Assurance:</strong> 
        <br>
        Rigorous testing processes guarantee bug-free and reliable mobile applications, providing a seamless experience for users.
    </p>
 
     <p>
        <strong>Ongoing Support:</strong>
        <br> 
        Beyond development, Pixelar Solutions offers continuous support and updates to keep your mobile app at its best and ahead of the competition.
    </p>
 
     <p>
        <strong>Ready to Launch Your App?</strong> 
        <br>
        Contact Pixelar Solutions for mobile app development that blends innovation, functionality, and user satisfaction seamlessly.
    </p>
        `
    },
    6: {
        name: "Software White Labeling",
        breadcrums: [{ name: 'Home', link: "/" }, { name: "Services", link: "/services" }, { name: "Business profile", link: "" }],
        imgSrc: require("../Assets/img/service/sv-7.png"),
        content: `
        <p>
        Pixelar Solutions specializes in crafting compelling business profiles that showcase the essence and uniqueness of your brand. Our business profile building service is designed to create a powerful online presence, leaving a lasting impression on your audience.
    </p>

     <p>
        <strong>Strategic Brand Representation:</strong> 
        <br>
        We strategically represent your brand by highlighting its core values, mission, and unique selling points, ensuring a strong and memorable brand image.
    </p>
 
     <p>
        <strong>Engaging Content:</strong>  
         <br>
         Our creative team develops content that resonates with your target audience, providing a captivating narrative that communicates your brand story effectively.
    </p>
 
     <p>
        <strong>Visual Identity:</strong>  
        <br> 
        Pixelar Solutions ensures a visually appealing business profile, incorporating design elements that align with your brand aesthetics and leave a positive impact on visitors
    </p>
 
     <p>
        <strong>Platform Optimization:</strong> 
         <br> We optimize your business profile for various online platforms, ensuring consistency and professionalism across websites, social media, and other digital channels.
    </p>
 
     <p>
        <strong>SEO Integration:</strong>  
         <br>
         Enhance discoverability with our SEO integration, allowing your business profile to rank higher in search engine results and attract a broader audience.
    </p>
 
     <p>
        <strong>Brand Cohesiveness:</strong> 
        <br>
          Pixelar Solutions ensures cohesiveness in your brand message, visual elements, and tone across all platforms, creating a unified and memorable brand experience.
        </p>
 
     <p>
        <strong>Continuous Brand Enhancement:</strong>   
        <br>
        We provide ongoing support to keep your business profile updated, relevant, and aligned with evolving industry trends, ensuring sustained brand success.
    </p>
 
     <p>
        <strong>Ready to Elevate Your Brand Presence?</strong>
         Contact Pixelar Solutions to leverage our expertise in business profile building and establish a compelling online presence that sets your brand apart.
    </p>
        `
    }
}

function ServiceDetails(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    const [serviceNumber, setServiceNumber] = useState(1);
    const location = useLocation();

    useEffect(() => {
        const { hash, pathname, search } = location;
        const serviceNum = pathname.split('-')[2] ?? 1;
        console.log(serviceNum, hash, pathname, search);
        setServiceNumber(serviceNum);
    }, [])

    return (
        <>
            <Banner name={PageNameObj[serviceNumber].name} breadcrums={PageNameObj[serviceNumber].breadcrums} />
            <main>
                <div className="sv-details-area pt-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="sv-details-content">
                                    <div className="sv-details-thumb mb-50">
                                        <img src={PageNameObj[serviceNumber].imgSrc} />                                    </div>
                                    <h1>Pixelar Solutions: {PageNameObj[serviceNumber].name}</h1>
                                    <div className='content' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(PageNameObj[serviceNumber].content) }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ThinkMakeSolve />
        </>
    );
}

export default ServiceDetails;