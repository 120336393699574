import React from 'react';
import { NavLink } from 'react-router-dom';

const projects = [
    {
        img: "/Assets/img/case/1.png",
        link: "http://khwaishenterprises.in/"
    },
    {
        img: "/Assets/img/case/2.png",
        link: "https://www.vamshiconventioncenter.com/"
    },
    {
        img: "/Assets/img/case/3.png",
        link: "https://edu5.in/"
    },
    {
        img: "/Assets/img/case/4.png",
        link: "http://littlelearnershub.in/"
    },
    {
        img: "/Assets/img/case/5.png",
        link: "https://eknathdevasthanharnai.com/"
    },
    {
        img: "/Assets/img/case/6.png",
        link: "https://pixelarsolutions.com/"
    },
    {
        img: "/Assets/img/case/7.png",
        link: "https://housecaregurus.in/"
    },
    {
        img: "/Assets/img/case/8.png",
        link: "http://myspaceinteriors.co/"
    },
    {
        img: "/Assets/img/case/9.png",
        link: "https://project.pixelarsolutions.com/"
    },
];

function CasestudyView(props) {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-12 wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".3s">
                    <div className="tp-case-section-title-box text-center mb-60">
                        <h4 className="tp-section-subtitle tp-green-color">Check our Work</h4>
                        <h3 className="tp-section-title">Some of our recent case studies</h3>
                    </div>
                </div>
            </div>
            <div className="row gx-20">
                {
                    projects.map((project, index) => (
                        <div className="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration=".7s" data-wow-delay=".7s" key={index}>
                            <div className="tp-case-thumb p-relative fix mb-20">
                                <img src={project.img} alt={`Project ${index + 1}`} />
                                <div className="case-icon">
                                    <NavLink to={ project.link } target="_blank" rel="noopener noreferrer">
                                        <i className="fas fa-arrow-alt-up"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* 
            <div className="row gx-20">
                <div className="col-xl-6 col-lg-6  wow tpfadeRight" data-wow-duration=".7s" data-wow-delay=".7s">
                    <div className="tp-case-thumb p-relative fix mb-20">
                        <img src={require('../Assets/img/case/case-2.jpg')} alt="" />
                        <div className="case-icon">
                            <a href="https://edu5.in/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6  wow tpfadeLeft" data-wow-duration=".7s" data-wow-delay=".9s">
                    <div className="tp-case-thumb p-relative fix mb-20">
                        <img src={require('../Assets/img/case/case-3.jpg')} alt="" />
                        <div className="case-icon">
                            <a href="https://www.teeart.in/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6  wow tpfadeLeft" data-wow-duration=".7s" data-wow-delay=".5s">
                    <div className="tp-case-thumb p-relative fix mb-20">
                        <img src={require('../Assets/img/case/case-1.jpg')} alt="" />
                        <div className="case-icon">
                            <a href="https://eknathdevasthanharnai.com/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6  wow tpfadeRight" data-wow-duration=".7s" data-wow-delay="1s">
                    <div className="tp-case-thumb p-relative fix mb-20">
                        <img src={require('../Assets/img/case/case-4.jpg')} alt="" />
                        <div className="case-icon">
                            <NavLink to={"/"} target="_blank"><i className="fas fa-arrow-alt-up"></i></NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="tp-case-button text-center mt-40">
                        <NavLink className="tp-btn" to={"/case-studies"}>all Case studies</NavLink>
                    </div>
                </div>
            </div>
            */}
        </div>
    );
}

export default CasestudyView;