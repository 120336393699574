import React, {useEffect} from 'react';
import { NavLink } from "react-router-dom";

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Banner from '../Components/Banner';
import Counter from '../Components/Counter';
import ServiceShow from '../Components/ServiceShow';
import ContactUs from '../Components/ContactUs';

import '../Assets/css/scroller.scss'

function About(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <div className="body-overlay"></div>
            <main>
                <Banner name="About Pixelar" breadcrums={[{ name: 'Home', link: "/" }, { name: "About", link: "" }]} />

                <div className="tp-about-us-area pt-120 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 wow tpfadeLeft" data-wow-duration=".7s" data-wow-delay=".5s">
                                <div className="ab-inner-content">
                                    <h4 className="ab-title-xs mb-25">Exploring Pixelar, Where <br /> Dreams Shape Digital Realities. <br /></h4>
                                    <p>At Pixelar Solutions, we embark on a journey fueled by innovation, precision, and a passion for transforming digital landscapes. Established with the vision of redefining excellence in web development, Pixelar Solutions stands at the forefront of creating immersive and dynamic online experiences.</p>
                                    <p>
                                        Pixelar Solutions specializes in a spectrum of services ranging from static website development, dynamic websites, web applications, to cutting-edge mobile app solutions.
                                    </p>

                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 wow tpfadeRight" data-wow-duration=".7s" data-wow-delay=".8s">
                                <div className="tp-img-inner text-end">
                                    <div className="row gx-3">
                                        <div className="col-xl-7 col-lg-6 col-md-7 col-7">
                                            <div className="ab-inner-img">
                                                <img src={require('../Assets/img/about/ab-inner-1.jpg')} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-6 col-md-5 col-5">
                                            <div className="ab-inner-img mb-20">
                                                <img src={require('../Assets/img/about/ab-inner-2.jpg')} alt="" />
                                            </div>
                                            <div className="ab-inner-img">
                                                <img src={require('../Assets/img/about/ab-inner-3.jpg')} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Counter />

                <ServiceShow />

                <div className="tp-case-area case-grident-bg pt-120 pb-120 fix">
                    <div className="container-fluid">
                        <div className="row g-0 justify-content-center">
                            <div className="col-xl-6">
                                <div className="tp-case-title-box mb-60 text-center wow tpfadeUp" data-wow-duration=".7s"
                                    data-wow-delay=".5s">
                                    <h3 className="tp-section-subtitle">My Portfolio</h3>
                                    <h3 className="tp-section-title-sm">Look at My Recent <br /> Case Studies</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tp-case-wrapper wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".7s">
                            <div className="slider">
                                <div className="slide-track">
                                    <div className="slide">
                                        <div className="tp-case-item text-center p-relative mb-30">
                                            <div className="tp-case-item__thumb fix">
                                                <a href="https://eknathdevasthanharnai.com/" target="_blank"><img src={require('../Assets/img/case/case-1.jpg')} alt="" /></a>
                                            </div>
                                            <div className="tp-circle-text">
                                                <a href="https://eknathdevasthanharnai.com/" target="_blank">Case <br /> Studies</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="tp-case-item text-center p-relative mb-30">
                                            <div className="tp-case-item__thumb fix">
                                                <a href="https://www.teeart.in/" target="_blank"><img src={require('../Assets/img/case/case-3.jpg')} alt="" /></a>
                                            </div>
                                            <div className="tp-circle-text">
                                                <a href="https://www.teeart.in/" target="_blank">Case <br /> Studies</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="tp-case-item text-center p-relative mb-30">
                                            <div className="tp-case-item__thumb fix">
                                                <a href="https://pixelarsolutions.com/"><img src={require('../Assets/img/case/case-4.jpg')} alt="" /></a>
                                            </div>
                                            <div className="tp-circle-text">
                                                <a href="https://pixelarsolutions.com/">Case <br /> Studies</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="tp-case-item text-center p-relative mb-30">
                                            <div className="tp-case-item__thumb fix">
                                                <a href="https://edu5.in/" target="_blank"><img src={require('../Assets/img/case/case-2.jpg')} alt="" /></a>
                                            </div>
                                            <div className="tp-circle-text">
                                                <a href="https://edu5.in/" target="_blank">Case <br /> Studies</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="tp-case-item text-center p-relative mb-30">
                                            <div className="tp-case-item__thumb fix">
                                                <a href="https://eknathdevasthanharnai.com/" target="_blank"><img src={require('../Assets/img/case/case-1.jpg')} alt="" /></a>
                                            </div>
                                            <div className="tp-circle-text">
                                                <a href="https://eknathdevasthanharnai.com/" target="_blank">Case <br /> Studies</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className="tp-case-item text-center p-relative mb-30">
                                            <div className="tp-case-item__thumb fix">
                                                <a href="https://www.teeart.in/" target="_blank"><img src={require('../Assets/img/case/case-3.jpg')} alt="" /></a>
                                            </div>
                                            <div className="tp-circle-text">
                                                <a href="https://www.teeart.in/" target="_blank">Case <br /> Studies</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".9s">
                            <div className="case-button mt-30 text-center">
                                <NavLink className="tp-btn" to={"/case-studies"}>more Case Studies</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tp-case-area case-grident-bg pt-120 pb-120 fix">
                    <div className="container-fluid">
                        <div className="row g-0 justify-content-center">
                            <div className="col-xl-6">
                                <div className="tp-case-title-box mb-60 text-center wow tpfadeUp" data-wow-duration=".7s"
                                    data-wow-delay=".5s">
                                    <h3 className="tp-section-subtitle">Testimonial</h3>
                                    <h3 className="tp-section-title-sm">Trusted by Clients.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="tp-case-wrapper wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".7s">
                            <div className="slider">
                                <div className="slide-track">

                                    <div className="slide w-350" data-wow-duration=".7s" data-wow-delay=".6s">
                                        <div className="tp-testimonial-three__content mb-20">
                                            <div className="tp-testimonial-three__content-icon">
                                                <i className="fas fa-quote-right"></i>
                                            </div>
                                            <div className="tp-testimonial-three__content-text">
                                                <p>A seamless and collaborative experience – Pixelar is our trusted ally in the world of web development.</p>
                                            </div>
                                        </div>
                                        <div className="tp-testimonial-three__user">

                                            <div className="tp-testimonial-three__user-content text-center">
                                                <h4 className="user-title">Gaurav k</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide w-350" data-wow-duration=".7s" data-wow-delay=".s7">
                                        <div className="tp-testimonial-three__content mb-20">
                                            <div className="tp-testimonial-three__content-icon content-icon-1">
                                                <i className="fas fa-quote-right"></i>
                                            </div>
                                            <div className="tp-testimonial-three__content-text">
                                                <p>Pixelar Solutions exceeded our expectations! Their creativity and precision in web development are unmatched. A reliable partner for digital success.</p>
                                            </div>
                                        </div>
                                        <div className="tp-testimonial-three__user">
                                            <div className="tp-testimonial-three__user-content text-center">
                                                <h4 className="user-title">Amol P</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide w-350" data-wow-duration=".7s"
                                        data-wow-delay=".8s">
                                        <div className="tp-testimonial-three__content mb-20">
                                            <div className="tp-testimonial-three__content-icon content-icon-2">
                                                <i className="fas fa-quote-right"></i>
                                            </div>
                                            <div className="tp-testimonial-three__content-text">
                                                <p>Pixelar's team delivered pixel-perfect solutions on time. Professionalism, innovation, and quality make them our go-to for web development.</p>
                                            </div>
                                        </div>
                                        <div className="tp-testimonial-three__user">
                                            <div className="tp-testimonial-three__user-content text-center">
                                                <h4 className="user-title">Aakash M</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide w-350" data-wow-duration=".7s"
                                        data-wow-delay=".9s">
                                        <div className="tp-testimonial-three__content mb-20">
                                            <div className="tp-testimonial-three__content-icon content-icon-3">
                                                <i className="fas fa-quote-right"></i>
                                            </div>
                                            <div className="tp-testimonial-three__content-text text-center">
                                                <p>Choosing Pixelar was a game-changer. Their expertise transformed our vision into a stunning reality. Highly recommended for top-tier web solutions.</p>
                                            </div>
                                        </div>
                                        <div className="tp-testimonial-three__user">
                                            <div className="tp-testimonial-three__user-content text-center">
                                                <h4 className="user-title">Nikhil P</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide w-350" data-wow-duration=".7s"
                                        data-wow-delay="1s">
                                        <div className="tp-testimonial-three__content mb-20">
                                            <div className="tp-testimonial-three__content-icon content-icon-4">
                                                <i className="fas fa-quote-right"></i>
                                            </div>
                                            <div className="tp-testimonial-three__content-text">
                                                <p>Pixelar Solutions stands out for their commitment to excellence. Seamless communication, innovative thinking, and a passion for quality define their approach.</p>
                                            </div>
                                        </div>
                                        <div className="tp-testimonial-three__user">
                                            <div className="tp-testimonial-three__user-content text-center">
                                                <h4 className="user-title">Gopal j</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide w-350" data-wow-duration=".7s"
                                        data-wow-delay="1.1s">
                                        <div className="tp-testimonial-three__content mb-20">
                                            <div className="tp-testimonial-three__content-icon content-icon-5">
                                                <i className="fas fa-quote-right"></i>
                                            </div>
                                            <div className="tp-testimonial-three__content-text">
                                                <p>Pixelar is more than a development agency; they are partners in success. Creative, responsive, and consistently exceeding expectations. Thrilled with the results!</p>
                                            </div>
                                        </div>
                                        <div className="tp-testimonial-three__user">
                                            <div className="tp-testimonial-three__user-content text-center">
                                                <h4 className="user-title">Priyanka S</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".9s">
                            <div className="case-button mt-30 text-center">
                                <NavLink className="tp-btn" to={"/case-studies"}>more Case Studies</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <ContactUs />
            </main>

        </>
    );
}

export default About;