import React from 'react';
import { NavLink } from "react-router-dom";

function Footer(props) {
    return (
        <footer>
            <div className="tp-footer-area footer-bg pt-130">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-30">
                            <div className="tp-footer-widget z-index-3">
                                <div className="tp-logo footer-logo">
                                    <NavLink to={"/"}>
                                        <img src={require('../Assets/img/logo/logo-2.png')} alt="" />
                                    </NavLink>
                                </div>
                                <div className="tp-footer-widget__para mt-30">
                                    <p>
                                        Pixelar solution provices everything to bring your business online. We provide domain name
                                        suitable for your business, website with amazing UI and hosting with 99.99% uptime.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-6 col-12 mb-30">
                            <div className="tp-footer-widget z-index-3">
                                <div className="tp-footer-widget__title">
                                    <h4 className="tp-footer-title">Company</h4>
                                </div>
                                <div className="tp-footer-widget__list mt-30">
                                    <ul>
                                        <li><NavLink to={"/"}>Home</NavLink></li>
                                        <li><NavLink to={"/about-us"}>About us</NavLink></li>
                                        <li><NavLink to={"/services"}>Services</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-12 mb-30">
                            <div className="tp-footer-widget footer-space-one z-index-3">
                                <div className="tp-footer-widget__title">
                                    <h4 className="tp-footer-title">Services</h4>
                                </div>
                                <div className="d-flex ">
                                    <div className="tp-footer-widget__list mt-20">
                                        <ul>
                                            <li><NavLink to={"/service-details"}>Website development</NavLink></li>
                                            <li><NavLink to={"/service-details-2"}>Webapp for business</NavLink></li>
                                            <li><NavLink to={"/service-details-3"}>E-Commerce solution</NavLink></li>
                                        </ul>
                                        <ul>
                                            <li><NavLink to={"/service-details-4"}>Social media management</NavLink></li>
                                            <li><NavLink to={"/service-details-5"}>Mobile app development</NavLink></li>
                                            <li><NavLink to={"/service-details-6"}>Business profile</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-30">
                            <div className="tp-footer-widget z-index-3">
                                <div className="tp-footer-widget__title">
                                    <h4 className="tp-footer-title">Contact Us</h4>
                                </div>
                                <div className="tp-footer-widget__list mt-20">
                                    <ul className="footer-position ">
                                        <li><a href="https://maps.app.goo.gl/m8M71uYPkfM1eybw5" target="_blank">
                                            <span><i className="fal fa-map-marker-alt"></i></span>
                                            106, Rohit Palm, North Wing, Rahatani <br />Pune, Maharashtra
                                        </a>
                                        </li>
                                        <li><a href="mailto:info@pixelarsolutions.com">
                                            <span><i className="fal fa-envelope-open"></i></span>
                                            info@pixelarsolutions.com</a>
                                        </li>
                                        <li><a href="tel:8983418183">
                                            <span><i className="fal fa-phone-alt"></i></span>
                                            8983418183</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="tp-newsletter-wrapper z-index-3">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 col-12">
                                        <div className="tp-newsletter">
                                            <div className="tp-newsletter__title">
                                                <h4 className="tp-newsletter-title">Get latest updates</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-12">
                                        <div className="tp-newsletter">
                                            <div className="tp-newsletter__input p-relative">
                                                <form action="#">
                                                    <input type="email" placeholder="Enter your mail" />
                                                    <button className="tp-btn-yellow">Subscribe <i className="far fa-arrow-right"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right-wrapper z-index-3">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 col-12">
                                <div className="copyright-left text-center text-lg-start">
                                    <p>©Copyright @2024 All Rights Reserved By <NavLink to={"/"}>Pixelar solutions</NavLink></p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 col-12">
                                <div className="copyright-right-side text-center text-lg-end">
                                    <ul>
                                        <li><NavLink to={"/privacy-policy"}>Privacy Policy</NavLink></li>
                                        <li><NavLink to="/terms-used">Terms of Use</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button className="scroll-top scroll-to-target wp-floating" data-target="html">
                <NavLink className="tp-search-box" target="_blank" href="https://wa.me/+918983418183?text=I%20want%20to%20know%20more"><i
                    className="fab fa-whatsapp"></i></NavLink>
            </button>
        </footer>
    );
}

export default Footer;