import React, {useEffect} from 'react';

import Navbar from '../Components/Navbar';
import Banner from '../Components/Banner';
import Footer from '../Components/Footer';
import ContactUs from '../Components/ContactUs';
import CasestudyView from '../Components/CasestudyView';

function CaseStudies(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <div className="body-overlay"></div>
            <main>
                <Banner name="Case Studies - 2024" breadcrums={[{ name: 'Home', link: "/" }, { name: "Case Studies", link: "" }]} />
                <div className="tp-case-area pt-120 pb-120">
                    {/* <div className="container">
                        <div className="row gx-20">
                            <div className="col-xl-6 col-lg-6">

                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src={require("../Assets/img/case/case-2.jpg")} alt="" />
                                        <div className="case-icon">
                                            <a href="https://edu5.in/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src={require("../Assets/img/case/case-3.jpg")} alt="" />
                                        <div className="case-icon">
                                            <a href="https://www.teeart.in/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src={require("../Assets/img/case/case-1.jpg")} alt="" />
                                        <div className="case-icon">
                                            <a href="https://eknathdevasthanharnai.com/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src="assets/img/case/case-4.jpg" alt="">
                                        <div className="case-icon">
                                            <a href="https://pixelarsolutions.com/" target="_blank"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div>
                            <!-- <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src="assets/img/case/case-3-1.jpg" alt="">
                                        <div className="case-icon">
                                            <a href="case-details.html"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div> -->
                            <!-- <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src="assets/img/case/case-3-2.jpg" alt="">
                                        <div className="case-icon">
                                            <a href="case-details.html"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div> -->
                            <!-- <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src="assets/img/case/case-3-3.jpg" alt="">
                                        <div className="case-icon">
                                            <a href="case-details.html"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div> -->
                            <!-- <div className="col-xl-6 col-lg-6">
                                <div className="tp-case-thumb p-relative fix mb-20">
                                    <img src="assets/img/case/case-3-4.jpg" alt="">
                                        <div className="case-icon">
                                            <a href="case-details.html"><i className="fas fa-arrow-alt-up"></i></a>
                                        </div>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div className="row">
                            <div className="col-xl-12">
                                <div className="tp-case-button text-center mt-40">
                                    <a className="tp-btn" href="case-details.html">all Case studeis</a>
                                </div>
                            </div>
                        </div> -->
                    </div> */}
                    <CasestudyView />
                </div>

                <ContactUs />

            </main>

        </>
    );
}

export default CaseStudies;