import React from 'react';

function ThinkMakeSolve(props) {
    return (
        <div class="tp-cta-area cta-space-three pt-120 pb-120">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <div class="cta-item p-relative">
                            <h3 class="cta-big-text"><a href="#">Think.</a></h3>
                            <div class="cta-effect-img">
                                <img src={require("../Assets/img/cta/cta-2.png")} alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <div class="cta-item p-relative">
                            <h3 class="cta-big-text"><a href="#">Make.</a></h3>
                            <div class="cta-effect-img">
                                <img src={require("../Assets/img/cta/cta-3.png")} alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <div class="cta-item p-relative">
                            <h3 class="cta-big-text"><a href="#">Solve.</a></h3>
                            <div class="cta-effect-img">
                                <img src={require("../Assets/img/cta/cta-4.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThinkMakeSolve;