import React, { useEffect } from 'react';
import SoftwareWhiteLabelingBlog from '../Components/SoftwareWhiteLabelingBlog';
import { Link } from 'react-router-dom';

function BlogDetails(props) {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <section class="breadcrumb__area breadcrumb-height include-bg p-relative" data-background="assets/img/breadcrumb/breadcurmb.jpg">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="breadcrumb__content">
                                <h3 class="breadcrumb__title">Blog Details</h3>
                                <div class="breadcrumb__list wow tpfadeUp" data-wow-duration=".9s">
                                    <span><Link to={"/"}>Home</Link></span>
                                    <span class="dvdr"><i class="fa fa-angle-right"></i></span>
                                    <span>Blog Details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SoftwareWhiteLabelingBlog />
        </div>
    );
}

export default BlogDetails;