import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import Banner from '../Components/Banner';
import Footer from '../Components/Footer';
import ContactUs from '../Components/ContactUs';

function Services(props) {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <main>
                <Banner name="Services" breadcrums={[{ name: 'Home', link: "/" }, { name: "Services", link: "" }]} />
                <div className="body-overlay"></div>
                <div className="tp-service-area pt-120 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 mb-30  wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".3s">
                                <div className="service-item-three text-center">
                                    <div className="service-item-three__img">
                                        <img src={require("../Assets/img/service/sv-4.png")} alt="" />
                                    </div>
                                    <div className="service-item-three__content">
                                        <h4 className="tp-service-sm-title"><NavLink to={"/service-details"}>Web <br /> Development</NavLink></h4>
                                        <p>Pixelar Solutions offers a transformative website development service, seamlessly blending creativity.</p>
                                    </div>
                                    <div className="service-item-three__button">
                                        <NavLink to={"/service-details"}>
                                            <span>
                                                <svg width="34" height="16" viewBox="0 0 34 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                            Read More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 mb-30  wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".5s">
                                <div className="service-item-three active text-center">
                                    <div className="service-item-three__img">
                                        <img src={require("../Assets/img/service/sv-5.png")} alt="" />
                                    </div>
                                    <div className="service-item-three__content">
                                        <h4 className="tp-service-sm-title"><NavLink to={"/service-details-2"}>Web app<br />for business</NavLink></h4>
                                        <p>We provide all type of solutions to your business problems via webapps</p>
                                    </div>
                                    <div className="service-item-three__button">
                                        <NavLink to={"/service-details-2"}>
                                            <span>
                                                <svg width="34" height="16" viewBox="0 0 34 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                            Read More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 mb-30  wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".6s">
                                <div className="service-item-three text-center">
                                    <div className="service-item-three__img">
                                        <img src={require("../Assets/img/service/sv-6.png")} alt="" />
                                    </div>
                                    <div className="service-item-three__content">
                                        <h4 className="tp-service-sm-title"><NavLink to={"/service-details-3"}>E-Commerce solutions</NavLink></h4>
                                        <p>Pixelar Solutions revolutionizes e-commerce with tailored solutions designed to amplify your online business</p>
                                    </div>
                                    <div className="service-item-three__button">
                                        <NavLink to={"/service-details-3"}>
                                            <span>
                                                <svg width="34" height="16" viewBox="0 0 34 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                            Read More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 mb-30 wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".8s">
                                <div className="service-item-three text-center">
                                    <div className="service-item-three__img">
                                        <img src={require("../Assets/img/service/sv-1.png")} alt="" />
                                    </div>
                                    <div className="service-item-three__content">
                                        <h4 className="tp-service-sm-title"><NavLink to={"/service-details-4"}>Social media <br /> management</NavLink></h4>
                                        <p>We elevates your online presence with expert social media management driving meaningful connections and results</p>
                                    </div>
                                    <div className="service-item-three__button">
                                        <NavLink to={"/service-details-4"}>
                                            <span>
                                                <svg width="34" height="16" viewBox="0 0 34 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                            Read More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 mb-30  wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".9s">
                                <div className="service-item-three active text-center">
                                    <div className="service-item-three__img">
                                        <img src={require("../Assets/img/service/sv-2.png")} alt="" />
                                    </div>
                                    <div className="service-item-three__content">
                                        <h4 className="tp-service-sm-title"><NavLink to={"/service-details-5"}>Mobile app<br /> development</NavLink></h4>
                                        <p>Pixelar Solutions pioneers mobile app development, crafting innovative solutions that seamlessly blend functionality and user experience.</p>
                                    </div>
                                    <div className="service-item-three__button">
                                        <NavLink to={"/service-details-5"}>
                                            <span>
                                                <svg width="34" height="16" viewBox="0 0 34 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                            Read More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 mb-30  wow tpfadeUp" data-wow-duration=".7s" data-wow-delay="1s">
                                <div className="service-item-three text-center">
                                    <div className="service-item-three__img">
                                        <img style={{width:"172px"}} src={require("../Assets/img/service/sv-7.png")} alt="" />
                                    </div>
                                    <div className="service-item-three__content">
                                        <h4 className="tp-service-sm-title"><NavLink to={"/service-details-6"}>Software white <br />labeling </NavLink></h4>
                                        <p>Pixelar Solutions we provide software white labeling to achieve your goals without your 100% involvement.</p>
                                    </div>
                                    <div className="service-item-three__button">
                                        <NavLink to={"/service-details-6"}>
                                            <span>
                                                <svg width="34" height="16" viewBox="0 0 34 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M33.7071 8.70711C34.0976 8.31659 34.0976 7.68342 33.7071 7.2929L27.3431 0.928935C26.9526 0.53841 26.3195 0.53841 25.9289 0.928934C25.5384 1.31946 25.5384 1.95262 25.9289 2.34315L31.5858 8L25.9289 13.6569C25.5384 14.0474 25.5384 14.6805 25.9289 15.0711C26.3195 15.4616 26.9526 15.4616 27.3431 15.0711L33.7071 8.70711ZM-8.74228e-08 9L33 9L33 7L8.74228e-08 7L-8.74228e-08 9Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                            Read More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tp-about-area ab-area-sapce pb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-5 order-2 order-lg-1 wow tpfadeLeft" data-wow-duration=".7s" data-wow-delay=".5s">
                                <div className="tp-ab-wrapper p-relative">
                                    <div className="tp-ab-shape-one z-index-3">
                                        <img src={require("../Assets/img/about/about-shape-1.png")} alt="" />
                                    </div>
                                    <div className="tp-ab-shape-two z-index-3">
                                        <img src={require("../Assets/img/about/about-circle-shape.png")} alt="" />
                                    </div>
                                    <div className="tp-about-thumb">
                                        <img src={require("../Assets/img/about/about-img.jpg")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 order-1 order-lg-2 wow tpfadeRight" data-wow-duration=".7s" data-wow-delay=".8s">
                                <div className="tp-ab-section-title-box">
                                    <h4 className="tp-section-subtitle tp-green-color">Who we are?</h4>
                                    <h3 className="tp-section-title">We are transforming Ideas into Impactful Digital Realities</h3>
                                    <p>We are a dynamic team of web development enthusiasts, passionate about creating pixel-perfect solutions that not only meet but exceed expectations. Our commitment to excellence is ingrained in every line of code we write, every design we craft, and every solution we deliver.</p>
                                    <NavLink className="tp-btn" to={"/about-us"}>About our Agency</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactUs />
            </main>

        </>
    );
}

export default Services;