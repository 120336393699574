import { Routes, Route, Link, NavLink } from "react-router-dom";
import React, { lazy, Suspense, useEffect } from 'react';
import WOW from 'wowjs';


import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '../src/Assets/css/animate.css';
import '../src/Assets/css/custom-animation.css';
import '../src/Assets/css/swiper-bundle.css';
import '../src/Assets/css/nice-select.css';
import '../src/Assets/css/flaticon.css';
import '../src/Assets/css/font-awesome-pro.css';
import '../src/Assets/css/magnific-popup.css';
import '../src/Assets/css/spacing.css';
import '../src/Assets/css/style.css';

// const Home = lazy(() => import("./Views/Home.js"));
// const About = lazy(() => import("./Views/About.js"));
// const CaseStudies = lazy(() => import("./Views/CaseStudies.js"));
// const Services = lazy(() => import("./Views/Services.js"));
// const Contact = lazy(() => import("./Views/Contact.js"));
// const ServiceDetails = lazy(() => import("./Views/ServiceDetails.js"));
// const PrivacyPolicy = lazy(() => import("./Views/PrivacyPolicy.js"));
// const Terms = lazy(() => import("./Views/Terms.js"));

// const Home = import("./Views/Home.js");
import Home from "./Views/Home.js";
// const About = import("./Views/About.js");
import About from "./Views/About.js";
// const CaseStudies = import("./Views/CaseStudies.js");
import CaseStudies from "./Views/CaseStudies.js"
// const Services = import("./Views/Services.js");
import Services from "./Views/Services.js"
// const Contact = import("./Views/Contact.js");
import Contact from "./Views/Contact.js";
// const ServiceDetails = import("./Views/ServiceDetails.js");
import ServiceDetails from "./Views/ServiceDetails.js";
// const PrivacyPolicy = import("./Views/PrivacyPolicy.js");
import PrivacyPolicy from "./Views/PrivacyPolicy.js";
// const Terms = import("./Views/Terms.js");
import Terms from "./Views/Terms.js";
import Blogs from "./Views/Blogs.js";
import Navbar from "./Components/Navbar.js";
import Footer from "./Components/Footer.js";
import SoftwareWhiteLabelingBlog from "./Components/SoftwareWhiteLabelingBlog.js";
import BlogDetails from "./Views/BlogDetails.js";

function App() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (

    <>
      <Suspense fallback={
        <div id="preloader">
          <div className="preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      }>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/case-studies" element={<CaseStudies />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/service-details" element={<ServiceDetails />} />
          <Route exact path="/service-details-2" element={<ServiceDetails />} />
          <Route exact path="/service-details-3" element={<ServiceDetails />} />
          <Route exact path="/service-details-4" element={<ServiceDetails />} />
          <Route exact path="/service-details-5" element={<ServiceDetails />} />
          <Route exact path="/service-details-6" element={<BlogDetails />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/blogs/software-white-labeling" element={<BlogDetails />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-used" element={<Terms />} />
        </Routes>
        <Footer />
      </Suspense>
    </>

  );
}

export default App;
